<template>
    <section class="default-header-layout" :class="{ 'default-header-layout--unpinned': scrollData.scrolled }">
        <Transition name="fade">
            <div v-if="headerData.isExpandPC" class="global-screen-blur" />
        </Transition>
        <!-- Header子菜单 默认不展开 -->
        <div
            :class="{
                'default-header-container-mask': true,
                'default-header-container-mask-expand-pc': headerData.isExpandPC,
                'default-header-container-mask-expand-mobile': mobileActive,
                'default-header-container-mask-has-bottom-content': headerData.showFlowingText,
            }"
        >
            <Transition name="custom-fade">
                <!-- PC端 一级菜单 hover展示内容 -->
                <div
                    v-if="headerData.isExpandPC"
                    class="default-header-submenu-container"
                    @mouseleave="(payload) => onResetPCMenu(payload)"
                >
                    <div class="default-header-submenu">
                        <div class="default-header-submenu-title">
                            {{ $t(curentMenu.title) }}
                        </div>
                        <div class="default-header-submenu-right">
                            <!-- 二级菜单 列表 -->

                            <!-- 一维数组展成二维数组  目前只有产品页需要-->
                            <div
                                v-if="NAV_MAPPER_GROUP_MAPPER[curentMenu.routePath]"
                                class="default-header-submenu-group-link"
                            >
                                <div
                                    class="default-header-submenu-group-item-link"
                                    v-for="group in NAV_MAPPER_GROUP_MAPPER[curentMenu.routePath]"
                                >
                                    <div class="default-header-submenu-group-item-title">{{ group.title }}</div>
                                    <div class="default-header-submenu-link">
                                        <CommonComponentsRouteLink
                                            v-for="(subMenuLink, subIndex) in curentMenu.submenu.slice(
                                                group.slice[0],
                                                group.slice[1],
                                            )"
                                            :key="subIndex"
                                            :route-path="subMenuLink.routePath"
                                            :link-text="subMenuLink.linkText"
                                            class="default-header-submenu-link-item"
                                            @mouseenter="setActiveSubMenuLink(subMenuLink)"
                                            @click="headerData.isExpandPC = false"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-else class="default-header-submenu-link">
                                <CommonComponentsRouteLink
                                    v-for="(subMenuLink, subIndex) in curentMenu.submenu"
                                    :key="subIndex"
                                    :route-path="subMenuLink.routePath"
                                    :link-text="subMenuLink.linkText"
                                    class="default-header-submenu-link-item"
                                    @mouseenter="setActiveSubMenuLink(subMenuLink)"
                                    @click="headerData.isExpandPC = false"
                                />
                            </div>
                            <!--  二级菜单 hover展示内容-->
                            <div v-if="headerData.activeSubMenuLink" class="default-header-submenu-content">
                                <div>
                                    <div class="default-header-submenu-content-title">
                                        {{
                                            headerData.activeSubMenuLink.linkTextLong
                                                ? $t(headerData.activeSubMenuLink.linkTextLong)
                                                : $t(headerData.activeSubMenuLink.linkText)
                                        }}
                                    </div>
                                    <div
                                        class="default-header-submenu-content-des"
                                        :class="{ mt24: NAV_MAPPER_GROUP_MAPPER[curentMenu.routePath] }"
                                    >
                                        {{ $t(headerData.activeSubMenuLink.des) }}
                                    </div>
                                    <div class="default-header-submenu-content-des-mini">
                                        {{ $t(headerData.activeSubMenuLink.mini_dex) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 移动端 Nav -->
                <div v-else-if="headerData.isExpandMobile" class="default-header-mobile-nav-container">
                    <!-- <Transition name="quick-fade" mode="out-in"> -->
                    <!-- 一级菜单 -->
                    <div class="default-header-mobile-nav">
                        <div
                            v-for="(item, itemIndex) in headerData.headerMenu"
                            :key="itemIndex"
                            class="default-header-mobile-nav-item"
                            :class="`default-header-mobile-nav-item-${itemIndex}`"
                            @click="
                                () => {
                                    // 展开子菜单后不可点击
                                    if (!headerData.isExpandMobileSubmenu) toggleMobileSubmenu(itemIndex);
                                }
                            "
                        >
                            {{ $t(item.title) }}
                        </div>
                    </div>
                    <!-- 二级菜单 -->
                    <!-- 一维数组展成二维数组  目前只有产品页需要-->
                    <template v-if="NAV_MAPPER_GROUP_MAPPER[curentMenu.routePath]">
                        <div class="default-header-mobile-nav-submenu-title" style="opacity: 0">
                            {{ $t(curentMenu.title) }}
                        </div>
                        <div class="default-header-submenu-group-link">
                            <div
                                class="default-header-submenu-group-item-link"
                                v-for="group in NAV_MAPPER_GROUP_MAPPER[curentMenu.routePath]"
                            >
                                <div class="default-header-submenu-group-item-title" style="opacity: 0">
                                    {{ group.title }}
                                </div>
                                <div class="default-header-mobile-nav-submenu">
                                    <CommonComponentsRouteLink
                                        v-for="(submenuItem, itemIndex) in curentMenu.submenu.slice(
                                            group.slice[0],
                                            group.slice[1],
                                        )"
                                        :key="itemIndex"
                                        :route-path="submenuItem.routePath"
                                        :link-text="submenuItem.linkText"
                                        class="default-header-mobile-nav-submenu-item"
                                        @click="hideMobileNav"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else class="default-header-mobile-nav-submenu">
                        <div class="default-header-mobile-nav-submenu-title" style="opacity: 0">
                            {{ $t(curentMenu.title) }}
                        </div>
                        <CommonComponentsRouteLink
                            v-for="(submenuItem, itemIndex) in curentMenu.submenu"
                            :key="itemIndex"
                            :route-path="submenuItem.routePath"
                            :link-text="submenuItem.linkText"
                            class="default-header-mobile-nav-submenu-item"
                            @click="hideMobileNav"
                        />
                    </div>
                    <!-- </Transition> -->
                </div>
                <!-- 移动端 申请试用表单 -->
                <div v-else-if="headerData.isExpandMobileForm" class="default-header-mobile-apply-form">
                    <div class="default-header-mobile-apply-form-title">
                        {{ $t('global_home_header_form_title_2') }}
                    </div>
                    <CommonComponentsModalApplyForm form-type="mobile" />
                    <div class="default-header-mobile-apply-form-help">{{ $t('global_home_header_form_help') }}</div>
                </div>
            </Transition>
        </div>
        <!-- Header本体 -->
        <div class="default-header-container global-limited-1360px-container">
            <div class="default-header-left">
                <Transition name="quick-fade">
                    <NuxtLink v-if="!mobileActive" class="default-header-logo" to="/">
                        <CommonComponentsSVGCommonLogo />
                    </NuxtLink>
                    <CommonComponentsSVGCommonBackArrow
                        v-else-if="headerData.isExpandMobileSubmenu"
                        :is-active="headerData.isExpandMobile"
                        class="default-header-left-back-icon"
                        @click="toggleMobileSubmenu(-1)"
                    />
                </Transition>
            </div>
            <!-- nav-pv 仅在PC端显示 一级菜单 -->
            <ul class="default-header-menu-ul nav-pc" @mouseleave="(payload) => onResetPCMenu(payload)">
                <li
                    v-for="(item, itemIndex) in headerData.headerMenu"
                    :key="itemIndex"
                    :class="['default-header-menu-li', `default-header-menu-li-${itemIndex}`]"
                >
                    <div
                        class="default-header-menu-tab"
                        @mouseenter="onHoverPCMenu(itemIndex)"
                        @click="handleClickPCmenu(item.routePath)"
                    >
                        {{ $t(item.title) }}
                        <div
                            :class="{
                                'default-header-menu-tab-indicator': true,
                                'default-header-menu-tab-indicator-active': isActiveNav(item.routePath),
                            }"
                        />
                    </div>
                </li>
            </ul>
            <div class="default-header-toolbar">
                <Transition name="quick-fade">
                    <div v-if="!mobileActive" class="default-header-toolbar-icon">
                        <CommonComponentsButtonLocaleChanger />
                        <CommonComponentsButtonLine
                            id="apply-trial-button"
                            :button-text="t('global_link_button_8')"
                            size="small"
                            transparent
                            light
                            @click="showButton"
                        />
                    </div>
                </Transition>
                <CommonComponentsSVGCommonMenuIcon
                    class="default-header-toolbar-icon-mobile"
                    :is-active="mobileActive"
                    @click="toggleMobileNav"
                />
            </div>
        </div>
        <Transition name="fade">
            <div v-if="headerData.enableFlowingText && showFlowingText" class="header-bottom-container">
                <CommonComponentsHeaderFlowingText
                    text="CommonComponentsHeaderFlowingText"
                    :on-handel-close="closeFlowingText"
                />
            </div>
        </Transition>
    </section>
</template>

<script lang="ts" setup>
    import { computed, reactive, onMounted, onUnmounted, ref } from 'vue';
    import i18n from '@/locales';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import {
        NAV_MAPPER_CN,
        NAV_MAPPER_GROUP_MAPPER_CN,
        NAV_MAPPER_EN,
        NAV_MAPPER_GROUP_MAPPER_EN,
    } from '~~/composables/useRoutesMapper.ts';
    import { allowScroll, preventScroll } from '~~/composables/useScrollController.ts';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import { sleep } from '~~/tools/date';
    const isChinese = ifCNLocale();

    const { t } = i18n.global;
    const { $bus } = useNuxtApp();

    interface SubMenuLink {
        linkText: string;
        des: string;
        mini_dex: string;
    }

    //  路由保持态
    const route = useRoute();
    const isHomePage = computed(() => {
        const path = route.path || route.fullPath;
        return path === '/';
    });

    const isActiveNav = (itemPath) => {
        // 剔除外链影响;
        if (itemPath.indexOf('http') > -1) return false;
        const path = route.path || route.fullPath;
        return path.split('/')[1] === itemPath.split('/')[1];
    };

    const isActiveSubNav = (itemPath) => {
        const path = route.path || route.fullPath;
        return path === itemPath;
    };

    const mobileActive = computed(() => headerData.isExpandMobile || headerData.isExpandMobileForm);

    const NAV_MAPPER_GROUP_MAPPER = isChinese ? NAV_MAPPER_GROUP_MAPPER_CN : NAV_MAPPER_GROUP_MAPPER_EN;

    const headerData = reactive<{
        isExpandPC: boolean;
        isExpandMobile: boolean;
        isExpandMobileSubmenu: boolean;
        isExpandMobileForm: boolean;
        curMenuIndex: number;
        headerMenu: any;
        enableFlowingText: boolean;
        activeSubMenuLink?: {
            linkText: string;
            linkTextLong?: string;
            des: string;
            mini_dex: string;
        };
    }>({
        isExpandPC: false,
        isExpandMobile: false, // 移动端界面下 nav是否展开
        isExpandMobileSubmenu: false, // 移动端界面下是否处于子菜单界面
        isExpandMobileForm: false,
        curMenuIndex: 0,
        headerMenu: isChinese ? NAV_MAPPER_CN : NAV_MAPPER_EN,
        enableFlowingText: false, // 开启滚动文字
    });

    const curentMenu = computed(() => headerData.headerMenu[headerData.curMenuIndex]);

    const setMenuIndex = (menuIndex: number) => {
        headerData.curMenuIndex = menuIndex;
    };

    const setActiveSubMenuLink = (subMenuLink: SubMenuLink) => {
        headerData.activeSubMenuLink = subMenuLink;
    };

    // —————————————————————————————————————————————— 首页顶部滚动文字 FlowingText控制
    const showFlowingText = computed(
        () => isHomePage.value && headerData.enableFlowingText && !headerData.isExpandPC && !mobileActive.value,
    );
    const closeFlowingText = () => {
        headerData.enableFlowingText = false;
    };

    // ——————————————————————————————————————————————PC端nav控制
    let timeout: NodeJS.Timeout;
    const handleClickPCmenu = (path: string) => {
        headerData.isExpandPC = false;
        pushRouter(path);
    };
    const toggleMenu = (): void => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // console.log('打开');
            headerData.isExpandPC = true;
        }, 200);
    };
    const onHoverPCMenu = (menuIndex: number) => {
        setMenuIndex(menuIndex);
        const menuFirst: SubMenuLink = headerData.headerMenu[menuIndex].submenu[0];
        const menuCurrent = headerData.headerMenu[menuIndex].submenu.filter((submenu) => {
            return isActiveSubNav(submenu.routePath);
        });
        if (menuCurrent.length > 0) setActiveSubMenuLink(menuCurrent[0]);
        else setActiveSubMenuLink(menuFirst);

        if (headerData.isExpandPC === false) {
            toggleMenu();
        }
    };

    const onResetPCMenu = (event: MouseEvent) => {
        clearTimeout(timeout);
        // 获取mouseover事件的目标dom
        const toElement = event.toElement || event.relatedTarget;
        const submenu = document.querySelector('.default-header-submenu') as HTMLElement;
        const nav = document.querySelector('.default-header-menu-ul') as HTMLElement;
        // console.log('toElement', toElement);
        if (
            headerData.isExpandPC === true &&
            (!toElement || !(submenu.contains(toElement) || submenu === toElement)) &&
            (!toElement || !(nav.contains(toElement) || nav === toElement))
        ) {
            timeout = setTimeout(() => {
                headerData.isExpandPC = false;
            }, 200);
        }
    };

    // ——————————————————————————————————————————————Header 向下滚动隐藏
    const scrollData = reactive({
        limitPosition: 500,
        scrolled: false,
        lastPosition: 0,
    });
    const handleScroll = () => {
        if (scrollData.lastPosition > window.scrollY) {
            scrollData.scrolled = false;
            $bus.$emit('hide:floatingButton');
        } else if (scrollData.limitPosition < window.scrollY && !headerData.isExpandPC && !mobileActive.value) {
            scrollData.scrolled = true;

            // const { $bus } = useNuxtApp();
            $bus.$emit('close:localeChanger');
            $bus.$emit('show:floatingButton');
            // move up!
        }
        //移动端悬浮按钮显示方法
        if (scrollData.limitPosition < window.scrollY && ifMatched.value) {
            $bus.$emit('show:floatingButton');
        } else if (ifMatched.value) {
            $bus.$emit('hide:floatingButton');
        }

        scrollData.lastPosition = window.scrollY;
        // this.scrolled = window.scrollY > 250;
    };
    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });

    // ——————————————————————————————————————————————移动端nav控制

    // 打开与关闭弹窗
    const openMobileNav = () => {
        headerData.isExpandMobileForm = true;
        scrollData.scrolled = false;
        // $lenis.stop();
        preventScroll();
    };

    const closeMobileNav = () => {
        headerData.isExpandMobileForm = false;
        // $lenis.stop();
        allowScroll();
    };

    onMounted(() => {
        // 监听事件
        $bus.$on('show:mobileNav', () => {
            openMobileNav();
        });
        $bus.$on('close:mobileNav', () => {
            closeMobileNav();
        });
    });

    const toggleMobileNav = () => {
        // isActive.value = !isActive.value;
        allowScroll();
        headerData.isExpandMobileSubmenu = false;
        if (headerData.isExpandMobileForm) {
            headerData.isExpandMobileForm = false;
            // $lenis.start();
            allowScroll();
        } else if (headerData.isExpandMobile) {
            headerData.isExpandMobile = false;
            // $lenis.start();
            allowScroll();
        } else {
            headerData.isExpandMobile = true;
            // $lenis.stop();
            preventScroll();
        }
    };

    const hideMobileNav = () => {
        headerData.isExpandMobile = false;
        headerData.isExpandMobileSubmenu = false;
    };
    // 点击导航栏显示子菜单, 传递menuIndex为负数时不设置curMenuindex，便于归位
    const toggleMobileSubmenu = async (menuIndex: number) => {
        const changeDuration = 0.3;
        if (menuIndex >= 0) setMenuIndex(menuIndex);

        await sleep(0);

        headerData.isExpandMobileSubmenu = !headerData.isExpandMobileSubmenu;

        const navItemNode = document.querySelector(
            `.default-header-mobile-nav-item-${headerData.curMenuIndex}`,
        ) as HTMLElement;
        const navItemNodes = (getGsap().utils.toArray('.default-header-mobile-nav-item') as HTMLElement[]).filter(
            (item) => item !== navItemNode,
        );
        const subMenuNode = getGsap().utils.toArray('.default-header-mobile-nav-submenu') as HTMLElement[];

        const subGroupTitleNode = getGsap().utils.toArray('.default-header-submenu-group-item-title') as HTMLElement[];

        console.log('toggleMobileSubmenu', subGroupTitleNode);

        if (headerData.isExpandMobileSubmenu) {
            getGsap()
                .timeline()
                .to(navItemNode, {
                    // 选中的菜单项放大
                    y: () => -menuIndex * navItemNode.scrollHeight,
                    scale: 2,
                    transformOrigin: 'left top',
                    ease: 'power3.out',
                    duration: changeDuration,
                })
                .to(
                    // 未选中的向左移动出屏幕外
                    navItemNodes,
                    {
                        opacity: 0,
                        duration: changeDuration,
                    },
                    '<',
                )
                .set('.default-header-mobile-nav-submenu', {
                    display: 'flex',
                })
                .to(subGroupTitleNode, {
                    opacity: 0.6,
                    duration: changeDuration,
                })
                .to(
                    // 子菜单从自右向左滑入
                    subMenuNode,
                    {
                        opacity: 1,
                        duration: changeDuration,
                    },
                );
        } else {
            getGsap()
                .timeline()
                .to(subMenuNode, {
                    opacity: 0,
                    duration: changeDuration,
                    // stagger: 0.1,
                })
                .to(subGroupTitleNode, {
                    opacity: 0,
                    duration: changeDuration,
                })
                .to('.default-header-mobile-nav-submenu', {
                    opacity: 0,
                    duration: changeDuration,
                    display: 'none',
                })
                .to(
                    [navItemNode, ...navItemNodes],
                    {
                        y: 0,
                        scale: 1,
                        opacity: 1,
                        duration: changeDuration / 2,
                    },
                    '<',
                );
        }
    };

    // ————————————————————————————————————————————————modal
    const showButton = () => {
        if (ifMatched.value) {
            // 移动 端，展开Header
            if (isChinese) {
                $bus.$emit('show:mobileNav');
            } else {
                $bus.$emit('show:applyButton');
            }
        } else {
            if (isChinese) {
                $bus.$emit('show:applyModal');
            } else {
                $bus.$emit('show:applyButton');
            }
        }
    };

    const ifMatched = ref(false);

    onMounted(() => {
        ifMatched.value = matchMobile();
    });
</script>

<style lang="less" scoped>
    @header-mobile-width: 900px;
    @header-menu-transition-duration: 0.2s;
    @header-sub-menu-transition-duration: 0.8s;
    @default-header-height: 52px;
    @default-bottom-box-height: 40px;
    @default-border-light: 1px solid rgba(255, 255, 255, 0.2);
    .custom-fade {
        &-enter-active {
            transition: opacity 0.5s ease-in;
        }
        &-enter-from {
            opacity: 0%;
        }
        &-enter-to {
            opacity: 100%;
        }
    }

    .mobile-transprant {
        opacity: 0;
    }

    .default-header-layout {
        // position: -webkit-sticky;
        position: fixed;
        top: 0;
        z-index: 100;
        width: min(100vw, 100%);
        height: @default-header-height;
        color: #ffffff;
        transition: all 1s ease;
        font-family: Montserrat, 'HarmonyOS Sans SC';

        .default-header-container {
            position: relative;
            height: @default-header-height;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 101;

            .default-header-left {
                z-index: 100;
                position: absolute;
                height: 100%;
                left: 0;
                display: flex;
                align-items: center;

                .default-header-logo {
                    display: flex;
                    align-items: center;
                    font-size: @font-size-xl;
                    font-weight: @font-weight-md;

                    :deep(.svg-logo) {
                        height: 26px;
                        width: auto;
                    }

                    @media (max-width: @header-mobile-width) {
                        padding: .convert(40px) [ @vw] 0;
                    }
                }

                &-back-icon {
                    position: absolute;
                    left: 0;
                    height: 28px;
                    width: auto;
                }
            }

            .default-header-toolbar {
                position: absolute;
                height: @default-header-height;
                right: 0;
                z-index: 100;
                font-size: @font-size-xl;
                font-weight: bold;

                display: flex;
                align-items: center;
                gap: @flex-gap-xl;

                .default-header-toolbar-icon {
                    display: flex;
                    align-items: center;
                    gap: 11px;
                    height: 100%;

                    cursor: pointer;

                    #apply-trial-button {
                        :deep(.button-flex-container) {
                            padding: 12px 16px;
                        }
                    }
                }
                .default-header-toolbar-icon-mobile {
                    display: none;
                    @media (max-width: @header-mobile-width) {
                        display: block;
                    }
                }

                @media (max-width: @header-mobile-width) {
                    padding-right: 0;
                    gap: 12px;
                }
            }
        }
        .global-screen-blur {
            z-index: 99;
            position: fixed;
            width: 100%;
            height: 150vh;
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(20px);
        }

        .default-header-container-mask {
            position: absolute;
            z-index: 99;
            left: 0;
            width: 100%;
            height: @default-header-height;
            max-height: @default-header-height;
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(20px);
            transition: all @header-menu-transition-duration linear;
            // background-color @header-menu-transition-duration linear;;
            .default-header-submenu-container {
                position: relative;
                width: 100%;
                margin-top: @default-header-height;
                border-top: @default-border-light;
                // opacity: 0;
                .default-header-submenu {
                    // display: none;
                    position: relative;
                    width: .convert(1360px) [ @vw];
                    // height: 323px;
                    transform: translateX(-50%);
                    // top: @default-header-height;
                    left: 50%;
                    background-color: transparent;
                    z-index: 100;
                    transition: all;
                    transition: 1s;
                    // opacity: 0;

                    display: grid;
                    grid-template-columns: 360fr 1000fr;
                    grid-template-rows: 1fr;
                    grid-column-gap: 0px;
                    grid-row-gap: 0px;
                    opacity: 1;

                    &-title,
                    &-link,
                    &-content {
                        display: flex;
                        padding-top: 40px;
                        padding-bottom: 60px;
                    }

                    .default-header-submenu-title {
                        // align-items: center;
                        font-family: Montserrat;
                        font-size: .convert(@font-size-title) [ @vw];
                        line-height: 100%;
                        white-space: wrap;
                        text-transform: capitalize;
                    }

                    .default-header-submenu-right {
                        display: flex;
                        .default-header-submenu-group-link {
                            padding-top: 40px;
                            padding-bottom: 0px;
                            .default-header-submenu-group-item-link {
                                margin-bottom: 40px;
                                .default-header-submenu-group-item-title {
                                    color: #fff;
                                    opacity: 0.6;
                                    font-size: 12px;
                                    margin-bottom: 24px;
                                }
                                .default-header-submenu-link {
                                    padding-top: 0px;
                                    padding-bottom: 0px;
                                }
                            }
                        }
                        .default-header-submenu-link {
                            width: .convert(260px) [ @vw];
                            min-width: .convert(260px) [ @vw];

                            flex-direction: column;
                            align-items: flex-start;
                            gap: .convert(24px) [ @vw];
                            white-space: nowrap;

                            font-style: normal;
                            font-size: .convert(@font-size-lg-ssm) [ @vw];
                            // text-transform: capitalize;

                            & a:hover {
                                /* identical to box height */
                                text-decoration-line: underline;
                                text-transform: capitalize;
                                cursor: pointer;
                            }
                        }

                        .default-header-submenu-content {
                            display: flex;

                            padding-left: .convert(60px) [ @vw];
                            border-left: @default-border-light;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                            .default-header-submenu-content-title {
                                font-family: Montserrat;
                                line-height: .convert(@font-size-title-xs) [ @vw];
                                white-space: nowrap;
                                font-size: .convert(@font-size-title-xs) [ @vw];
                            }

                            .default-header-submenu-content-des {
                                font-size: .convert(20px) [ @vw];
                                margin-top: 24px;
                                font-family: 'HarmonyOS Sans SC';
                                opacity: 0.8;
                            }

                            .default-header-submenu-content-des-mini {
                                font-size: .convert(@font-size-md) [ @vw];
                                margin-top: .convert(24px) [ @vw];
                                font-family: 'HarmonyOS Sans SC';
                            }
                        }
                    }
                }
            }

            .default-header-mobile-nav-container {
                position: absolute;
                top: @default-header-height;
                padding: 30px;
                .default-header-mobile-nav {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    z-index: 99;
                    &-item {
                        white-space: nowrap;
                        font-size: 20px;
                        height: 50px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                }
                .default-header-submenu-group-link {
                    padding-top: 28px;
                    padding-bottom: 0px;
                    .default-header-submenu-group-item-link {
                        margin-bottom: 28px;
                        .default-header-submenu-group-item-title {
                            color: #fff;
                            opacity: 0.6;
                            font-size: 12px;
                            margin-bottom: 20px;
                        }
                        .default-header-submenu-link {
                            padding-top: 0px;
                            padding-bottom: 0px;
                        }
                    }
                }
                .default-header-mobile-nav-submenu {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    z-index: 100;
                    display: none;
                    &-title {
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                    }

                    &-item {
                        color: #fff;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                }
            }

            .default-header-mobile-apply-form {
                display: flex;
                flex-direction: column;
                width: 84vw;
                height: 100vh; /* Fallback for browsers that do not support Custom Properties */
                height: calc(var(--vh, 1vh) * 100);
                margin: 0 auto;
                position: relative;

                &-title {
                    font-size: 32px;
                    text-transform: capitalize;
                    display: flex;
                    align-items: flex-end;
                    padding-top: 30px;
                    padding-bottom: 20px;
                }

                :deep(.ant-form) {
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    // height: 90%;
                    .ant-checkbox {
                        &-inner {
                            background-color: transparent;
                        }
                    }
                }

                &-help {
                    position: absolute;
                    font-size: 12px;
                    white-space: nowrap;
                    left: 50%;
                    bottom: 30px;
                    transform: translate(-50%);
                }

                @media (max-height: @screen-mobile-width) {
                }
            }
        }
        // .default-header-container-mask-has-bottom-content {
        //     height: ~'calc(@{default-header-height} + @{default-bottom-box-height})';
        //     max-height: 400px;
        //     transition: all @header-menu-transition-duration linear;
        // }
        .default-header-container-mask-expand-pc {
            position: absolute;
            left: 0;
            height: fit-content;
            max-height: unset;
            // background: rgba(0, 0, 0, 0.2);
            // backdrop-filter: blur(20px);
        }
        .default-header-container-mask-expand-mobile {
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            max-height: unset;
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(20px);
        }

        &--pinned {
            transform: translateY(0%);
        }
        &--unpinned {
            transform: translateY(-100%);
        }

        .header-bottom-container {
            top: 100%;
            width: 100%;
            z-index: 100;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            background: rgba(0, 50, 255, 0.2);
        }
    }

    .default-header-menu-ul.nav-pc {
        position: relative;
        height: @default-header-height;
        margin: 0;
        z-index: 100;
        display: flex;
        width: .convert(640px) [ @vw];
        --padding-li: .convert(36px) [ @vw];
        transform: translateX(calc(0px - var(--padding-li)));
        // gap: @flex-gap-xxxxxl;

        @media (max-width: @header-mobile-width) {
            display: none;
        }
        .default-header-menu-li {
            display: flex;
            align-items: center;
            padding: 0 var(--padding-li);

            .default-header-menu-tab {
                font-family: Montserrat;
                position: relative;
                box-sizing: border-box;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                // font
                font-style: normal;
                font-size: @font-size-md;
                text-transform: capitalize;

                border: none;
                outline: none;
                // padding: 8px 0;
                background-color: inherit;

                white-space: nowrap;

                &:hover {
                    cursor: pointer;
                    border-bottom: 0px;
                    .default-header-menu-tab-indicator {
                        position: absolute;
                        bottom: 0;
                        display: block;
                        border-color: #ffffff99;
                    }
                }

                .default-header-menu-tab-indicator {
                    position: absolute;
                    display: none;
                    width: 100%;
                    height: 4px;
                    bottom: 0;
                    border-bottom: 2px solid #ffffff;
                    border-radius: 2px;
                }

                .default-header-menu-tab-indicator-active {
                    display: block;
                    border-color: #ffffff;
                }
            }
        }
    }

    :lang(en) {
        .default-header-menu-ul.nav-pc {
            --padding-li: .convert(26px) [ @vw];
        }

        .default-header-submenu-container {
            // opacity: 0;
            .default-header-submenu {
                .default-header-submenu-title {
                    line-height: .convert(40px) [ @vw] !important;
                    font-size: .convert(40px) [ @vw] !important;
                }
            }
        }
    }
</style>
